import type { DialogProps } from "@reach/dialog";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import classNames from "components/ui/classNames";

import { forwardRef } from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const Dialog = forwardRef<
  HTMLDivElement,
  DialogProps & {
    className?: string;
  }
>(({ allowPinchZoom = false, initialFocusRef, isOpen, onDismiss = noop, className, ...props }, forwardedRef) => {
  return (
    <DialogOverlay
      allowPinchZoom={allowPinchZoom}
      initialFocusRef={initialFocusRef}
      isOpen={isOpen}
      onDismiss={onDismiss}
      className="fixed bottom-0 left-0 right-0 top-0 z-20 overflow-auto"
      style={{
        background: "rgba(73, 62, 84, 0.5)",
      }}
    >
      <DialogContent
        ref={forwardedRef}
        className={classNames("mx-auto my-[10vh] flex rounded-xl bg-white shadow-2xl outline-none", className)}
        {...props}
      />
    </DialogOverlay>
  );
});

export default Dialog;
