/* eslint-disable @typescript-eslint/no-empty-interface */
import classNames from "components/ui/classNames";
import isDarkModeSupportedPage from "components/ux/isDarkModeSupportedPage";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useQueryState } from "nuqs";
import type { ReactNode } from "react";

interface MobileNavbarMenuItemProps {
  title: string;
  description?: string;
  href: string;
  icon?: ReactNode;
  iconClassName?: string;
  titleClassName?: string;
  className?: string;
  variant?: "dark" | "light";
}
export default function MobileNavbarMenuItem({
  title,
  description,
  href,
  icon,
  iconClassName,
  titleClassName,
  className,
  variant = "light",
  ...props
}: MobileNavbarMenuItemProps) {
  const pathname = usePathname();
  const isActive = pathname.includes(href);
  const [theme] = useQueryState("theme");
  // eslint-disable-next-line no-underscore-dangle
  const _href = theme !== null && isDarkModeSupportedPage(href) ? `${href}?theme=${theme}` : href;

  return (
    <Link
      href={_href}
      as={_href}
      passHref={true}
      className={classNames(
        "group",
        "px-4 py-4",
        "text-sm tracking-wide text-gray-600 dark:font-medium dark:text-slate-300",
        "hover:bg-slate-100 focus:outline-none dark:hover:bg-slate-800",
        "flex items-center justify-between space-x-6",
        className,
      )}
      {...props}
    >
      <div className="space-y-1">
        <span
          className={classNames("font-bold text-slate-600", "dark:text-white", titleClassName, {
            "text-blue-600 dark:text-orange-300": isActive,
          })}
        >
          {title}
        </span>
        {description && <p className="text-xs tracking-wider text-slate-500 dark:text-slate-400">{description}</p>}
      </div>
      {/* Icon */}
      {variant === "dark" ? (
        <div className="z-0 flex size-10 items-center justify-center rounded-2xl bg-slate-800 p-1 squircle group-hover:bg-slate-900">
          <div className={classNames("p-1.5 text-white", iconClassName, "bg-transparent")}>{icon}</div>
        </div>
      ) : (
        <div className={classNames("z-0 flex h-10 w-10 items-center justify-center", "dark:border-transparent")}>
          <div
            className={classNames("inline-flex rounded-2xl p-2.5 squircle", "dark:border-transparent", iconClassName)}
          >
            {icon}
          </div>
        </div>
      )}
    </Link>
  );
}
