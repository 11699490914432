/* eslint-disable react/function-component-definition */

export default function isDarkModeSupportedPage(page: string) {
  return [
    "/",
    "/financials",
    "/compare",
    "/issuers",
    "/discussions",
    "/fatos-relevantes",
    "/ranking",
    "/comments",
  ].some((candidate) => {
    return candidate.length === 1 ? page === candidate : page.startsWith(candidate);
  });
}
