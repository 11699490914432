"use client";

import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import classNames from "components/ui/classNames";
import * as React from "react";

const ScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> & {
    orientation?: "vertical" | "horizontal";
  }
>(({ className, children, orientation, ...props }, ref) => (
  <ScrollAreaPrimitive.Root ref={ref} className={classNames("relative overflow-hidden", className)} {...props}>
    <ScrollAreaPrimitive.Viewport className="h-full w-full rounded-[inherit]">{children}</ScrollAreaPrimitive.Viewport>
    <ScrollBar orientation={orientation} />
    <ScrollAreaPrimitive.Corner />
  </ScrollAreaPrimitive.Root>
));
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>
>(({ className, orientation = "vertical", ...props }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    ref={ref}
    orientation={orientation}
    className={classNames(
      "flex touch-none select-none flex-col rounded-xl bg-slate-100 dark:bg-slate-950",
      "h-full w-full",
      {
        "w-3 p-0.5": orientation === "vertical",
        "h-3 p-0.5": orientation === "horizontal",
      },
      className,
    )}
    {...props}
  >
    <ScrollAreaPrimitive.ScrollAreaThumb
      className={classNames(
        "relative flex-1 cursor-grab rounded-xl",
        "bg-slate-950/20 hover:bg-slate-950/30",
        "dark:bg-slate-700",
      )}
    />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
));
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };
