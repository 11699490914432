import * as React from "react";

export default function DialogCloseX(props: React.HTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      {...props}
      type="button"
      aria-label="close dialog"
      className="box-border cursor-pointer border-none bg-transparent p-0"
    >
      <CloseXIcon aria-hidden={true} className="block" />
    </button>
  );
}

function CloseXIcon(props: React.SVGProps<any>) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
      <path
        d="M21 1L1 21M1 1l20 20"
        fill="none"
        fillRule="evenodd"
        stroke="#293B51"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
}
