import isoCountries from "i18n-iso-countries";
import { z } from "zod";

export const UserSettingsSchema = z.object({
  theme: z.enum(["DARK", "LIGHT"]).default("LIGHT"),
  country: z
    .string()
    .refine(
      (countryCode) => isoCountries.isValid(countryCode),
      (countryCode) => ({
        message: `Invalid country code: ${countryCode}`,
      }),
    )
    .optional()
    .nullable(),
  language: z.union([z.literal("en-US"), z.literal("pt-BR")]).default("pt-BR"),
  financialsTable: z
    .object({
      textAlignment: z
        .union([z.literal("LEFT"), z.literal("CENTER"), z.literal("RIGHT")])
        .describe("The text alignment of the table cells"),
      sortDirection: z.union([z.literal("ASC"), z.literal("DESC")]).describe("The sort direction of the table"),
      fullBleed: z.boolean().describe("Always show the table with full width"),
      // defaultTemplates: z
      //   .array(
      //     z.object({
      //       objectId: z.string().startsWith("ftemp"),
      //       vertical: z.union([z.literal("ACOES"), z.literal("FIIS"), z.literal("REITS"), z.literal("STOCKS")]),
      //       templateCodes: z
      //         .array(
      //           z.union([
      //             z.literal("NORMAL"),
      //             z.literal("MINING"),
      //             z.literal("INSURANCE"),
      //             z.literal("BANK"),
      //             z.literal("UTILITY"),
      //             z.literal("TRANSPORT"),
      //           ]),
      //         )
      //         .min(1)
      //         .max(6),
      //     }),
      //   )
      //   // 1 for FIIs
      //   // 3 for Ações (Normal, Banks & Insurers)
      //   // 3 for Stocks (Normal, Banks & Insurers)
      //   // 1 for REITs (Normal, Banks)
      //   .min(0)
      //   .max(8)
      //   .describe(`The User's default templates for each vertical. It can be at most 8 based on the rules above`),
    })
    .default({
      sortDirection: "ASC",
      textAlignment: "CENTER",
      fullBleed: false,
      // defaultTemplates: [],
    }),
  enabledIssuerPageBoxes: z
    .object({
      acoes: z.array(
        z.union([
          z.literal("ABOUT_BOX"),
          z.literal("CARDS"),
          z.literal("GOVERNANCE_BOX"),
          z.literal("EVENTS_BOX"),
          z.literal("OPERATING_SEGMENTS_BOX"),
          z.literal("LIQUIDITY_BOX"),
          z.literal("QUOTES_BOX"),
          z.literal("FINANCIALS_BOX"),
          z.literal("HIGHLIGHTS_BOX"),
          z.literal("DISCUSSIONS_BOX"),
        ]),
      ),
      stocks: z.array(
        z.union([
          z.literal("ABOUT_BOX"),
          z.literal("CARDS"),
          z.literal("GOVERNANCE_BOX"),
          z.literal("EVENTS_BOX"),
          z.literal("OPERATING_SEGMENTS_BOX"),
          z.literal("LIQUIDITY_BOX"),
          z.literal("QUOTES_BOX"),
          z.literal("FINANCIALS_BOX"),
          z.literal("HIGHLIGHTS_BOX"),
          z.literal("DISCUSSIONS_BOX"),
        ]),
      ),
      fiis: z.array(
        z.union([
          z.literal("ABOUT_BOX"),
          z.literal("CARDS"),
          z.literal("ASSETS_BOX"),
          z.literal("LIQUIDITY_BOX"),
          z.literal("QUOTES_BOX"),
          z.literal("FINANCIALS_BOX"),
          z.literal("DISCUSSIONS_BOX"),
        ]),
      ),
    })
    .default({
      acoes: [
        "ABOUT_BOX",
        "CARDS",
        "GOVERNANCE_BOX",
        "EVENTS_BOX",
        "OPERATING_SEGMENTS_BOX",
        "LIQUIDITY_BOX",
        "QUOTES_BOX",
        "FINANCIALS_BOX",
        "HIGHLIGHTS_BOX",
        "DISCUSSIONS_BOX",
      ],
      stocks: [
        "ABOUT_BOX",
        "CARDS",
        "GOVERNANCE_BOX",
        "EVENTS_BOX",
        "OPERATING_SEGMENTS_BOX",
        "LIQUIDITY_BOX",
        "QUOTES_BOX",
        "FINANCIALS_BOX",
        "HIGHLIGHTS_BOX",
        "DISCUSSIONS_BOX",
      ],
      fiis: ["ABOUT_BOX", "CARDS", "ASSETS_BOX", "LIQUIDITY_BOX", "QUOTES_BOX", "FINANCIALS_BOX", "DISCUSSIONS_BOX"],
    })
    .describe("The User's enabled boxes on the Issuer page"),
  hasClosedMarch24PerpetualBlackPopUp: z
    .boolean()
    .default(false)
    .describe("Determines if the User has closed the one-off March '24 Black popup"),
});

const keys = [...UserSettingsSchema.keyof().options];

export type UserSettingsShape = z.infer<typeof UserSettingsSchema>;
export type UserSettingsOptionsKeys = (typeof keys)[0];
export type UserSettingsOptionsPaths = Paths<UserSettingsShape>;

// Took this stuff from: https://stackoverflow.com/a/58436959
type Paths<T> = T extends object
  ? { [K in keyof T]: `${Exclude<K, symbol>}${"" | `.${Paths<T[K]>}`}` }[keyof T]
  : never;
