import NotificationListItem from "components/engagement/notifications/items/NotificationListItem";
import * as React from "react";
import Avatar from "../../../core/Avatar";
import CommentTime from "../../../discussions/CommentTime";
import NotificationActors from "../elements/NotificationActors";
import NotificationContentParagraph from "../elements/NotificationContentParagraph";
import SafeActorName from "../elements/SafeActorName";
import LinkToComment from "../elements/links/LinkToComment";
import type { AlsoRepliedToCommentNotification } from "../types";
import getActivityTimeAsUtc from "../utils/getActivityTimeAsUtc";
import pullActorsFromActivities from "../utils/pullActorsFromActivities";
import safeCommentOrReplyPreamble from "../utils/safeCommentOrReplyPreamble";

interface IAlsoRepliedToCommentNotificationListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  notification: AlsoRepliedToCommentNotification;
}

export default function AlsoRepliedToCommentNotificationListItem({
  notification,
}: IAlsoRepliedToCommentNotificationListItemProps) {
  // Shouldn't happen but just making sure so it doesn't break when people are using it.
  if (notification.activities.length === 0) {
    return null;
  }

  const topActivity = notification.activities[0];
  // When the author of the comment replies to its own comment
  const repliedToSelfCommentSentence = "respondeu ao comentário";
  // When others replies to someone else's comment
  const repliedToSomeoneElseCommentSentence = "também respondeu ao comentário de";
  // Tells whether it's the owner of the comment also replying to its own comment
  const isOwnComment = topActivity.actor.id === topActivity.commentAuthor.id;
  const replyPreamble = safeCommentOrReplyPreamble(topActivity.reply.data.bodyAsPlainText);
  const actors = (
    <NotificationActors
      maximumActorsConjunction={3}
      actors={pullActorsFromActivities(notification.activities).map((actor) => {
        return <SafeActorName key={actor.id} firstName={actor.data.firstName} />;
      })}
    />
  );

  return (
    <NotificationListItem notification={notification}>
      <Avatar
        src={topActivity.actor.data.pictureUrl}
        hashValue={topActivity.actor.id}
        alt={topActivity.actor.data.firstName}
        size={42}
        squircle={true}
      />

      <div className="grid w-72 gap-y-2 lg:w-full">
        <NotificationContentParagraph>
          {isOwnComment || !topActivity.commentAuthor.data ? (
            <LinkToComment activity={topActivity}>
              {actors} {repliedToSelfCommentSentence} <i>{`"${replyPreamble}"`}</i>
            </LinkToComment>
          ) : (
            <LinkToComment activity={topActivity}>
              {actors} {repliedToSomeoneElseCommentSentence}{" "}
              <SafeActorName firstName={topActivity.commentAuthor.data.firstName} /> <i>{`"${replyPreamble}"`}</i>
            </LinkToComment>
          )}
        </NotificationContentParagraph>

        <div className="text-right">
          <LinkToComment activity={topActivity}>
            <CommentTime timeStamp={getActivityTimeAsUtc(topActivity.time)} />
          </LinkToComment>
        </div>
      </div>
    </NotificationListItem>
  );
}
