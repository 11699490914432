import LinkToPost from "components/engagement/notifications/elements/links/LinkToPost";
import NotificationListItem from "components/engagement/notifications/items/NotificationListItem";
import type { RepliedToPostNotification } from "components/engagement/notifications/types";
import safeCommentOrReplyPreamble from "components/engagement/notifications/utils/safeCommentOrReplyPreamble";
import * as React from "react";
import Avatar from "../../../core/Avatar";
import CommentTime from "../../../discussions/CommentTime";
import NotificationActors from "../elements/NotificationActors";
import NotificationContentParagraph from "../elements/NotificationContentParagraph";
import SafeActorName from "../elements/SafeActorName";
import getActivityTimeAsUtc from "../utils/getActivityTimeAsUtc";
import pullActorsFromActivities from "../utils/pullActorsFromActivities";

interface IRepliedToPostNotificationListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  notification: RepliedToPostNotification;
}

export default function RepliedToPostNotificationListItem({ notification }: IRepliedToPostNotificationListItemProps) {
  // Shouldn't happen but just making sure so it doesn't break when people are using it
  if (notification.activities.length === 0) {
    return null;
  }

  const topActivity = notification.activities[0];
  // A single reply from a single user (actor)
  const isSingular = notification.actor_count === 1;
  const replyPreamble = safeCommentOrReplyPreamble(topActivity.reply.data.bodyAsPlainText);

  const hasObjectData =
    topActivity &&
    topActivity.object &&
    typeof topActivity.object === "object" &&
    topActivity.object.data &&
    typeof topActivity.object.data === "object";

  let postArea = "Post Area Not Found";

  if (hasObjectData && topActivity.object.data.contextType === "IRPF_FORUM") {
    postArea = "na área do Fórum Irpf";
  }

  if (hasObjectData && topActivity.object.data.contextType === "BLACK_FORUM") {
    postArea = "na área da Comunidade Black";
  }

  if (hasObjectData && topActivity.object.data.contextType === "BLACK_LESSON") {
    postArea = "na área Fundamentei Black";
  }

  const singularSentence = (
    <>
      respondeu ao seu comentário <i>{`"${replyPreamble}"`}</i> {postArea}
    </>
  );
  const pluralSentence = (
    <>
      responderam ao seu comentário <i>{`"${replyPreamble}"`}</i> {postArea}
    </>
  );

  const actors = (
    <NotificationActors
      maximumActorsConjunction={3}
      actors={pullActorsFromActivities(notification.activities).map((actor) => {
        return <SafeActorName key={actor.id} firstName={actor.data.firstName} />;
      })}
    />
  );

  return (
    <NotificationListItem notification={notification}>
      <Avatar
        src={topActivity.actor.data.pictureUrl}
        hashValue={topActivity.actor.id}
        alt={topActivity.actor.data.firstName}
        size={42}
        squircle={true}
      />

      <div className="grid w-72 gap-y-2 lg:w-full">
        <NotificationContentParagraph>
          <LinkToPost activity={topActivity}>
            {actors} {isSingular ? singularSentence : pluralSentence}
          </LinkToPost>{" "}
        </NotificationContentParagraph>

        <div className="text-right">
          <LinkToPost activity={topActivity}>
            <CommentTime timeStamp={getActivityTimeAsUtc(topActivity.time)} />
          </LinkToPost>
        </div>
      </div>
    </NotificationListItem>
  );
}
