export default function assignRef(ref: React.Ref<any>, value: any) {
  if (ref === null) {
    return;
  }

  if (typeof ref === "function") {
    ref(value);
    return;
  }

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line
    ref.current = value;
  } catch (err) {
    throw new Error(`Cannot assign value "${value}" to ref "${ref}"`);
  }
}
