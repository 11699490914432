import * as SelectPrimitive from "@radix-ui/react-select";
import { AuthContext } from "components/auth/Auth";
import { FatSelectItem, Select, SelectContent, SelectValue } from "components/ui/Select";
import classNames from "components/ui/classNames";
import useSelfie from "lib/hooks/useSelfie";
import { destroyCookie, parseCookies, setCookie } from "nookies";
import { invariant } from "outvariant";
import { useContext, useEffect, useState } from "react";
export const AVAILABLE_LANGUAGES = ["en-US", "pt-BR"];

interface PlanOverrideSwitcherProps {
  disabled?: boolean;
}
export default function PlanOverrideSwitcher({ disabled = false }: PlanOverrideSwitcherProps) {
  const selfie = useSelfie();
  const { refetch } = useContext(AuthContext);
  const [plan, setPlan] = useState<string | null>(() => {
    if (!selfie) {
      return "SEO";
    }
    return selfie?.isPremium ? "PREMIUM" : "FREE";
  });
  useEffect(() => {
    const cookies = parseCookies();
    if (cookies.__FUNDAMENTEI_PLAN_OVERRIDE) {
      setPlan(cookies.__FUNDAMENTEI_PLAN_OVERRIDE);
    }
  }, []);
  if (!plan) {
    return null;
  }
  invariant(
    process.env.FUNDAMENTEI_ENV !== "production",
    "This component should only be used in development for security purposes and financial sustainability",
  );
  return (
    <Select
      disabled={disabled}
      value={plan}
      onValueChange={(plan) => {
        if (plan !== "") {
          setCookie(null, "__FUNDAMENTEI_PLAN_OVERRIDE", plan, {
            maxAge: 21 * 24 * 60 * 60,
            path: "/",
          });
          setPlan(plan);
          refetch();
          return;
        }

        destroyCookie(null, "__FUNDAMENTEI_PLAN_OVERRIDE", {
          maxAge: 21 * 24 * 60 * 60,
          path: "/",
        });
        window.location.reload();
      }}
    >
      <SelectPrimitive.Trigger
        className={classNames(
          "flex h-10 w-10 items-center justify-center rounded-xl",
          "border",
          "outline-none",
          "hover:opacity-70",
        )}
      >
        <SelectValue asChild={true}>
          <span className="inline-flex">
            <PlanIcon plan={plan} />
          </span>
        </SelectValue>
      </SelectPrimitive.Trigger>

      <SelectContent className={classNames("rounded-lg border border-slate-200 text-sm")} side="top">
        {[
          { label: "SEO", plan: "SEO", description: "Visitante/SEO" },
          { label: "Free", plan: "FREE", description: "Usuário Free" },
          { label: "Premium", plan: "PREMIUM", description: "Usuário Premium" },
          { label: "Reset", plan: "", description: "Usuário logado" },
        ].map(({ label, plan, description }) => {
          return (
            <FatSelectItem key={label} value={plan} className="cursor-pointer space-x-4 rounded-md">
              <div className="flex w-44 items-center space-x-3">
                <PlanIcon plan={plan} />

                <div>
                  <h5 className="text-xs font-bold">{label}</h5>
                  <h6 className="text-xs text-slate-500">{description}</h6>
                </div>
              </div>
            </FatSelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
}

interface PlanIconProps {
  plan: string;
}
function PlanIcon({ plan }: PlanIconProps) {
  return (
    <div
      className={classNames(
        "flex h-6 w-6 items-center justify-center rounded-full text-center font-mono text-sm font-bold",
        "border",
        {
          "bg-gray-50 text-gray-700": plan === "SEO",
          "bg-teal-50 text-teal-700": plan === "FREE",
          "bg-indigo-50 text-indigo-700": plan === "PREMIUM",
          "bg-orange-50 text-orange-700": plan === "",
        },
      )}
    >
      {plan === "" ? "?" : plan.substring(0, 1)}
    </div>
  );
}
