import * as React from "react";
import { forwardRef } from "react";

interface IPopoverContentProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  backgroundColor: string;
  children: any;
}

export default forwardRef(({ backgroundColor, children, ...props }: IPopoverContentProps, ref: React.Ref<any>) => {
  return (
    <div
      ref={ref}
      className="z-[2] rounded shadow-md focus:outline-none"
      style={{
        backgroundColor,
      }}
      {...props}
    >
      {children}
    </div>
  );
});
