import * as React from "react";
import { forwardRef } from "react";

interface IPopoverArrowProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  arrowSize: number;
  placement: "top" | "left" | "bottom" | "right";
  arrowColor: string;
}

export default forwardRef(
  ({ arrowSize, arrowColor, placement, ...props }: IPopoverArrowProps, ref: React.Ref<HTMLDivElement>) => {
    if (placement === "top") {
      return (
        <div
          ref={ref}
          {...props}
          className="absolute m-0 h-0 w-0 border-solid"
          style={{
            bottom: -arrowSize,
            left: `calc(50% - ${arrowSize}px)`,
            marginTop: 0,
            marginBottom: 0,
            borderColor: arrowColor,
            borderWidth: `${arrowSize}px ${arrowSize}px 0 ${arrowSize}px`,
            borderRightColor: "transparent",
            borderBottomColor: "transparent",
            borderLeftColor: "transparent",
          }}
        />
      );
    }

    if (placement === "right") {
      return (
        <div
          ref={ref}
          {...props}
          className="absolute m-0 h-0 w-0 border-solid"
          style={{
            top: `calc(50% - ${arrowSize}px)`,
            left: -arrowSize,
            marginRight: 0,
            marginLeft: 0,
            borderColor: arrowColor,
            borderWidth: `${arrowSize}px ${arrowSize}px ${arrowSize}px 0`,
            borderTopColor: "transparent",
            borderBottomColor: "transparent",
            borderLeftColor: "transparent",
          }}
          {...props}
        />
      );
    }

    if (placement === "bottom") {
      return (
        <div
          ref={ref}
          {...props}
          className="absolute m-0 h-0 w-0 border-solid"
          style={{
            top: -arrowSize,
            left: `calc(50% - ${arrowSize}px)`,
            marginTop: 0,
            marginBottom: 0,
            borderColor: arrowColor,
            borderWidth: `0 ${arrowSize}px ${arrowSize}px ${arrowSize}px`,
            borderTopColor: "transparent",
            borderRightColor: "transparent",
            borderLeftColor: "transparent",
          }}
          {...props}
        />
      );
    }

    if (placement === "left") {
      return (
        <div
          ref={ref}
          {...props}
          className="absolute m-0 h-0 w-0 border-solid"
          style={{
            top: `calc(50% - ${arrowSize}px)`,
            right: -arrowSize,
            marginRight: 0,
            marginLeft: 0,
            borderColor: arrowColor,
            borderWidth: `${arrowSize}px 0 ${arrowSize}px ${arrowSize}px`,
            borderTopColor: "transparent",
            borderRightColor: "transparent",
            borderBottomColor: "transparent",
          }}
          {...props}
        />
      );
    }

    return null;
  },
);

export function getArrowParentContainerStyles(placement: "top" | "left" | "bottom" | "right") {
  if (!placement) {
    return {};
  }

  if (placement === "top") {
    return {
      transformOrigin: "bottom center",
    };
  }

  if (placement === "right") {
    return {
      transformOrigin: "left center",
    };
  }

  if (placement === "bottom") {
    return {
      transformOrigin: "top center",
    };
  }

  if (placement === "left") {
    return {
      transformOrigin: "right center",
    };
  }

  return {};
}
