import Portal from "@reach/portal";
import type { ReactElement } from "react";
import { useEffect, useRef } from "react";
import * as React from "react";
import { Manager, Popper, Reference } from "react-popper";
import palette from "../palette";
import assignRef from "../utils/assignRef";
import PopoverArrow, { getArrowParentContainerStyles } from "./PopoverArrow";
import PopoverContent from "./PopoverContent";

type TPopoverTriggerState = {
  accessibility: {
    "aria-haspopup": boolean;
    "aria-controls": string | undefined;
  };
  ref: (node: any) => void;
};

type TPopoverContentState = {
  onClose: () => void;
};

type TPlacement = "top" | "right" | "bottom" | "left";

interface IPopoverProps {
  id: string;
  className?: string;
  isOpen: boolean;
  placement?: TPlacement;
  trapFocus?: boolean;
  backgroundColor?: string;
  arrowColor?: string;
  arrowSize?: number;
  showArrow?: boolean;
  gutter?: number;
  onClose?: () => void;
  render: (state: TPopoverContentState) => ReactElement;
  children: (state: TPopoverTriggerState) => ReactElement;
  containerStyle?: React.CSSProperties;
  renderWithPortal?: boolean;
}

export default function Popover({
  id,
  className,
  isOpen,
  placement,
  trapFocus = false,
  arrowColor = palette.white,
  backgroundColor = palette.white,
  arrowSize = 8,
  showArrow = true,
  gutter = 0,
  onClose,
  render,
  children,
  containerStyle,
  renderWithPortal = true,
}: IPopoverProps) {
  const triggerRef = useRef<any>();
  const popperRef = useRef<any>();

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (
      !trapFocus &&
      isOpen &&
      popperRef.current &&
      triggerRef.current &&
      !popperRef.current.contains(event.relatedTarget) &&
      !triggerRef.current.contains(event.relatedTarget) &&
      onClose &&
      typeof onClose === "function"
    ) {
      onClose();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (!isOpen) {
        return;
      }

      if (triggerRef.current && event.target) {
        if (triggerRef.current === event.target) {
          return;
        }

        if (
          typeof (event.target as Element).isEqualNode === "function" &&
          (event.target as Element).isEqualNode(triggerRef.current)
        ) {
          return;
        }

        if (triggerRef.current.contains(event.target)) {
          return;
        }
      }

      if (
        popperRef.current &&
        event.target &&
        !popperRef.current.contains(event.target as Element) &&
        onClose &&
        typeof onClose === "function"
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick, false);

    return () => {
      return document.removeEventListener("mousedown", handleOutsideClick, false);
    };
  }, [isOpen]);

  return (
    <Manager>
      <Reference>
        {({ ref }) => {
          return children({
            accessibility: {
              "aria-haspopup": true,
              "aria-controls": isOpen ? id : undefined,
            },
            ref: (node) => {
              triggerRef.current = node;
              assignRef(ref, node);
            },
          });
        }}
      </Reference>

      <Popper
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, gutter || arrowSize + 4],
            },
          },
        ]}
        placement={placement || "auto"}
      >
        {({ ref, style: popperStyle, placement, arrowProps }) => {
          if (!isOpen) {
            return null;
          }

          const Wrapper = renderWithPortal && Portal ? Portal.Portal : React.Fragment;

          return (
            <Wrapper>
              <PopoverContent
                id={id}
                tabIndex={-1}
                aria-hidden={isOpen}
                backgroundColor={backgroundColor}
                ref={(node) => {
                  popperRef.current = node;
                  assignRef(ref, node);
                }}
                style={{
                  ...popperStyle,
                  ...getArrowParentContainerStyles(placement as TPlacement),
                  ...containerStyle,
                }}
                className={className}
                onBlur={handleBlur}
                onKeyDown={(event) => {
                  event.stopPropagation();

                  if (event.key === "Escape" && onClose && typeof onClose === "function") {
                    onClose();
                  }
                }}
              >
                {render({
                  onClose: () => {
                    if (onClose && typeof onClose === "function") {
                      onClose();
                    }
                  },
                })}

                {showArrow && ["top", "left", "bottom", "right"].includes(placement) && (
                  <PopoverArrow
                    ref={arrowProps.ref}
                    arrowSize={arrowSize}
                    arrowColor={arrowColor || backgroundColor}
                    placement={placement as TPlacement}
                    style={arrowProps.style}
                  />
                )}
              </PopoverContent>
            </Wrapper>
          );
        }}
      </Popper>
    </Manager>
  );
}
