import NotificationListItem from "components/engagement/notifications/items/NotificationListItem";
import type {
  ReactedToPostNotification,
  ReactedToPostReplyNotification,
} from "components/engagement/notifications/types";
import * as React from "react";
import Avatar from "../../../core/Avatar";
import CommentTime from "../../../discussions/CommentTime";
import NotificationActors from "../elements/NotificationActors";
import NotificationContentParagraph from "../elements/NotificationContentParagraph";
import SafeActorName from "../elements/SafeActorName";
import LinkToPost from "../elements/links/LinkToPost";
import getActivityTimeAsUtc from "../utils/getActivityTimeAsUtc";
import pullActorsFromActivities from "../utils/pullActorsFromActivities";
import safeCommentOrReplyPreamble from "../utils/safeCommentOrReplyPreamble";

interface IReactedToPostOrPostReplyNotificationListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  notification: ReactedToPostNotification | ReactedToPostReplyNotification;
}

export default function ReactedToPostOrPostReplyNotificationListItem({
  notification,
}: IReactedToPostOrPostReplyNotificationListItemProps) {
  // Shouldn't happen but just making sure so it doesn't break when people are using it.
  if (notification.activities.length === 0) {
    return null;
  }

  const topActivity = notification.activities[0];
  const reactionsSentence = notification.actor_count > 1 ? "reagiram no seu comentário" : "reagiu no seu comentário";
  const replyPreamble = safeCommentOrReplyPreamble(topActivity.object.data.bodyAsPlainText);

  const actors = (
    <NotificationActors
      maximumActorsConjunction={3}
      actors={pullActorsFromActivities(notification.activities).map((actor) => {
        return <SafeActorName key={actor.id} firstName={actor.data.firstName} />;
      })}
    />
  );

  return (
    <NotificationListItem notification={notification}>
      <Avatar
        src={topActivity.actor.data.pictureUrl}
        hashValue={topActivity.actor.id}
        alt={topActivity.actor.data.firstName}
        size={42}
        squircle={true}
      />

      <div className="grid w-72 gap-y-2 lg:w-full">
        <NotificationContentParagraph>
          <LinkToPost activity={topActivity}>
            {actors} {reactionsSentence} <i>{`"${replyPreamble}"`}</i>
          </LinkToPost>
        </NotificationContentParagraph>

        <div className="text-right">
          <LinkToPost activity={topActivity}>
            <CommentTime timeStamp={getActivityTimeAsUtc(topActivity.time)} />
          </LinkToPost>
        </div>
      </div>
    </NotificationListItem>
  );
}
